import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "./Layout";
import styled from "styled-components";
import TargetFullBanner from "../components/TargetFullBanner";
import DipendentePensionatoPrivato from "../sections/DipendentePensionatoPrivato";
import SliderOne from "../sections/SliderOne";
import MetodoPiuVeloce from "../sections/MetodoPiuVeloce";
import CessioneFinanziamenti from "../sections/CessioneFinanziamenti";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import readingTime from 'reading-time'
import BlogPostPreview from "../components/BlogPostPreview";
import MobileBlogPostsSlider from "../sections/MobileBlogPostsSlider";

const StyledWpPost = styled.div`
  .wp-post {
    &__title {
      min-height: 75px;
      &--yellow {
        color: #e7c700;
      }
    }

    &__readmore {
      min-height: 75px;
    }

    &__ttr-date {
        color: #6685A4;
    }

    &__slug a {
      text-decoration: none;
      color: #000;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .section {
    display: flex;
    align-items: center;
    padding-bottom: 3em;
    @media (max-width: 992px) {
      flex-direction: column;
    }
  }
`;

export default function WpPost({ data }) {
  const post = data.thisPost.nodes[0];
  const postsToGet = 3
  const randomPosts = data.allPosts.nodes.sort(() => 0.5 - Math.random()).slice(0, postsToGet)
  const [timeToRead, settimeToRead] = useState(null);

  useEffect(() => {
    settimeToRead(Math.round(readingTime(post.content).minutes));
  }, [post.content]);

  return (
    <Layout helmetTitle={post.seo.title} helmetDescription={post.seo.metaDesc}>
      <StyledWpPost id="post-content">
        <div className="wp-post__header">
          <div className="wrapper container d-flex align-items-center position-relative justify-content-between">
            <div className="d-flex align-items-center">
              <div className="d-flex flex-column"></div>
            </div>
          </div>
        </div>
        <div id="post-content" className="container mx-auto">
          <div className="wp-post__slug mb-5 mt-4 fs-5">
            <Link to="/" className="fw-bold">
              HOME
            </Link>{" "}
            &gt; <Link to="/blog/">
              Blog
            </Link>{" "}
            {post.categories && (
              <span>
                {" "}
                &gt;{" "}
                <Link to={"/blog/" + post.categories.nodes[0].slug + "/"}>
                  {" "}
                  {post.categories.nodes[0].name}
                </Link>{" "}
              </span>
            )}{" "}
            &gt; <Link to={"/blog/" + post.categories.nodes[0].slug + '/' + post.slug + '/'}>{post.title}</Link>
          </div>

          <div className="row">
            <div className="col-12 col-lg-8">
              <h1 className="wp-post__title wp-post__title--yellow text-center">{post.title}</h1>
              <GatsbyImage
                image={getImage(post.featuredImage.node.localFile)}
                className="img-fluid mb-5"
                alt="Thumbnail"
                aspectRatio="4/3"
              />
              <div className="d-flex wp-post__ttr-date justify-content-between">
                <p>Tempo di lettura: <span role="img" aria-label="Tempo">⌛</span> {timeToRead} {timeToRead === 1 ? 'minuto' : 'minuti'} </p>
                <p>{new Date(post.date).toLocaleString('it-IT', { day: 'numeric', month: 'long', year: 'numeric' })}</p>
              </div>
              <div
                className="wp-post__content"
                dangerouslySetInnerHTML={{ __html: post.content }}
              />
            </div>
            <div className="col-12 offset-lg-1 col-lg-3">
              <h3 className="wp-post__readmore d-none d-lg-block">Leggi anche...</h3>
              {randomPosts.map(post => (
                <BlogPostPreview post={post} className="d-none d-lg-block" />
              ))}

              <MobileBlogPostsSlider className="d-block d-lg-none">
                {randomPosts.map(post => (
                  <BlogPostPreview post={post} />
                ))}
              </MobileBlogPostsSlider>
            </div>
          </div>
        </div>
      </StyledWpPost>
      <TargetFullBanner
        title="Dipendente pubblico, privato o pensionato?"
        description="Richiedere un prestito non è mai stato così facile, veloce e conveniente!"
        className="mt-5"
      />
      <DipendentePensionatoPrivato />
      <SliderOne />
      <MetodoPiuVeloce className="mt-2" />
      <CessioneFinanziamenti className="mb-5" />
    </Layout>
  );
}

export const query = graphql`
  query ($slug: String!) {
    thisPost: allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        content
        slug
        date
        categories {
          nodes {
            name
            id
            slug
          }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                  quality: 75
                  layout: CONSTRAINED
                )
              }
            }
          }
        }
        seo {
            metaDesc
            title
            opengraphTitle
            metaKeywords
            twitterDescription
            twitterTitle
        }
      }
    },
    allPosts: allWpPost(sort: {fields: [date]}) {
        nodes {
          title
          slug
          excerpt
          categories {
            nodes {
              name
              id
              slug
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    quality: 75
                    width: 512
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
  }
`;
