import React from "react";
import SliderBlock from "../components/SliderBlock";
import { StaticImage } from "gatsby-plugin-image";

const NextArrow = (props) => (
  <StaticImage
    src="../assets/images/icons/slider-next.svg"
    className={props.className}
    alt="Prev slide"
    placeholder="blurred"
    layout="fixed"
    width={18}
    height={30}
    onClick={props.onClick}
    objectFit="contain"
  />
);

const PrevArrow = (props) => (
  <StaticImage
    src="../assets/images/icons/slider-prev.svg"
    className={props.className}
    alt="Prev slide"
    placeholder="blurred"
    layout="fixed"
    width={18}
    height={30}
    onClick={props.onClick}
    objectFit="contain"
  />
);

const settings = {
  arrows: false,
  infinite: true,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};

const MobileBlogPostsSlider = (props) => (
  <SliderBlock
    settings={settings}
    bgColor="transparent"
    className={`mt-5 ${props.className}`}
  >
      {props.children}
  </SliderBlock>
);

export default MobileBlogPostsSlider;
